import {
  TextField,
  Stack,
  Typography,
  Box,
  Snackbar,
  Alert,
  Link as MuiLink,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import Image from 'next/image';
import LoginImage from '@/images/login.png';
import { useAuth } from '@/hooks/useAuth';
import LoadingButton from '@mui/lab/LoadingButton';
import { useForm } from 'react-hook-form';
import { fieldRequiredMsg } from '@/utils/language';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';

export const COLOR = grey[400];
export const COLOR1 = grey[500];
const SIZE = 400;

export default function Login() {
  const { isLoggedIn, loading, error: authError, login } = useAuth();
  const [error, setError] = useState(null);
  const router = useRouter();

  useEffect(() => {
    setError(authError);
  }, [authError]);

  useEffect(() => {
    if (isLoggedIn) {
      router.push('/app');
    }
  }, [isLoggedIn, router]);

  const { register, getValues, handleSubmit } = useForm();

  const onSubmit = () => {
    const { emailAddress, password } = getValues();
    login(emailAddress, password);
  };

  return (
    <Box
      display="flex"
      minHeight="100vh"
      justifyContent="center"
      alignItems="center"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack style={{ width: '100%', maxWidth: SIZE }} spacing={2}>
          <Image src={LoginImage} width={SIZE} />
          <Stack spacing={2}>
            <TextField
              label="Email Address"
              type="email"
              variant="outlined"
              sx={{ width: SIZE }}
              {...register('emailAddress', {
                required: fieldRequiredMsg,
              })}
            />
            <TextField
              label="Password"
              type="password"
              variant="outlined"
              sx={{ width: SIZE }}
              {...register('password', { required: fieldRequiredMsg })}
            />
            {/* <Typography color={COLOR} sx={{ width: SIZE }} textAlign="right">
              Forget Password?
            </Typography> */}
          </Stack>
          <LoadingButton
            loading={loading}
            variant="contained"
            color="primary"
            sx={{ width: SIZE }}
            size="large"
            type="submit"
          >
            Log In
          </LoadingButton>
          <Typography color={COLOR1} textAlign="center">
            Don&apos;t have an account yet?{' '}
            <Link href="/registration">
              <MuiLink>Sign Up</MuiLink>
            </Link>
          </Typography>
        </Stack>
      </form>
      <Snackbar
        open={!!error}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setError(null)}
          severity="error"
          sx={{ width: '100%' }}
        >
          {error}
        </Alert>
      </Snackbar>
    </Box>
  );
}
