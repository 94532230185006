import { useAuthStore } from '@/stores/AuthStore';
import { useEffect, useState } from 'react';
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
} from 'firebase/auth';

export const useAuth = () => {
  const auth = useAuthStore((state) => state.auth);

  const user = useAuthStore((state) => state.user);
  const setUser = useAuthStore((state) => state.setUser);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const login = async (email, password) => {
    setError(null);
    setLoading(true);

    try {
      const response = await signInWithEmailAndPassword(auth, email, password);
      setUser(response.user);
    } catch (error) {
      setError(error.message);
    }

    setLoading(false);
  };

  const logout = async () => {
    setLoading(true);
    await auth.signOut();
    setLoading(false);
    setUser(null);
  };

  const register = async (email, password) => {
    setError(null);
    setLoading(true);

    try {
      const response = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      setUser(response.user);
    } catch (error) {
      setError(error.message);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (error) {
      console.error('useAuth error:', error);
    }
  }, [error]);

  useEffect(() => {
    if (!auth) {
      return;
    }

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoading(false);
    });
    return unsubscribe;
  }, [auth, setUser]);

  const isLoggedIn = !!user;

  return { isLoggedIn, auth, user, loading, error, login, logout, register };
};
